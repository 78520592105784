const URI = {

    getAllUsers: `getAllUsers`,
    //python APIs - https://dev.api.docsteth.com/elib/ - PY_API 
    getStates: `get-allstatenames`,
    getDistricts: `get-alldistricts-by-statename/`,
    getSubDistricts: `get-allsubdistricts-by-districtname/`,
    getVillages: `get-allvillages-by-subdistrictname/`,
    searchUniv: `search_universities/`,
    addUniv: `add_universities`,

    findPageName: `/findPageByName/`,
    addPage: `/addPages`,
    addKycDocs: `/addMultipleKycDocuments`,
    updateKycDocs: `/updateMultipleKycDocuments`,
    fileUpload: `/upload/file`,

    getPagesByUserId: `getPagesByUserId/`,
    getDocsByPageId: `/getAllDocumentByPageId/`,

    addSubPage: `addPagesForSubOrg`,
    deletePage: `deletePagesById/`,


    //Facility
    getOrganization: `Organization/identifier/www.docsteth.com/`,
    getOrganizationByPageId: `Organization/identifier/`,
    organization: 'Organization',
    updateOrg: 'Organization/',
    getOrganizationPartOf: 'Organization/name/',
    uploadhealthDepartment: 'HealthCareDepartment/Organization',
    uploadHealthServiceDept: 'HealthcareService',
    updateHealthServiceDept: 'HealthcareService',
    getHealthCareService: 'HealthcareService',
    getAllHealthCareServices: 'HealthcareService/identifier/pageId/',
    gethealthDepartmentByPageId: 'Organization/identifier/dept/pageId/',
    getHealthCareServiceList: 'HealthcareService/organization/',
    addComponents: '/addComponents/',
    getAddedComponents: '/getcomponentsByOrgId/',
    updateAddedComponents: 'updateComponentsById/',
    organizationPartOf: `Organization/partof/`,


    //location resource for organization
    addLocation: `Location`,
    getLocation: `Location/organization/`,
    getsearchLocation: `googlemapSearch/`,
    getsearchLocByPincode: `pincodeAddress/`,
    getLongAndLatVal: `googlemapsGeocode/`,
    getLocationDetails: `reverseGeocode/`,

    //organisation people
    getPendingRequests: `GetPendingRequestForApproval?IbmOrgId=`,
    changeStatus: `changeStatus/practitionerrole/`,
    excludeDuplicate: `Practitioner/Post/excludeDuplicate/`,

    /* https://dev.api.clinivault.org/fhir-server/api/v4/ - fhir server api */
    getSpecialities: `ValueSet?name=PracticeSettingCodeValueSet`,
    getServices: `HealthcareService/location/`,
    approvedPractitioners: `GetListOfApproved/Practitioner/`,

    approveDoctor: `Practitioner/Post/excludeDuplicate/`,

    // practitioner 
    getPractitionerGroup: `PractitionerRole/location/`,
    getPractitionersByHealthCareServiceId: `PractitionerRole/HealthcareService/`,
    getPractitionersByOrganizationId: `PractitionerRole/organization/`,
    getPermissionForPractitionerRole: `permissions/`,

    // get FacilityDetails
    getFacilitiesOfSubOrg: `resource/authorization/docsteth/Organization?partof=`,


    // update permission for Practitioner
    updatePermission: `update/permissions`,
    getPermissionHasorNot: `permissionsByUser/`



}

export { URI }