import React, { useState, useEffect } from "react";
import { Card, Grid, Button, InputLabel, Select, MenuItem, Paper, Avatar, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Tooltip } from "@mui/material";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrgLogo from "../../../../../../images/orglogo.svg";
import Settings from "../../../../../../icons/settings.svg";
import EditHealthCareDept from "../HealthCareDept/addHealthcareDept";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { OrgLayout } from "../../../../../common/components/layout";
import { handleHealthDeptLoc, handleHealthDeptOrg, getUserOrg, getIsAdmin } from "../../../../../../redux/Actions/regActions";
import { URI } from "../../../../../utility/uri";
import { DOC_API_WITHOUT_TOKEN, D_API } from "../../../../../../services/APIService";
import { ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from '@mui/icons-material/Edit';
import "./healthCareDept.css";
import Stethoscope from "../../../../../../../src/images/stethoscope1.svg";
import Location from "../../../../../../../src/images/location.svg";
import DoctorImg from "../../../../../../images/doctorImg.png";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LaunchIcon from '@mui/icons-material/Launch';
import Referal from "../../../../../../images/referral-3.svg";
import Communicate from "../../../../../../images/Communication-language.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DefaultHealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import SubHealthServices from "./addContent/subHealthservice";
import PractitionerGroup from "./addContent/practitionerGroup";
import MediaGallery from "../HealthCareDept/addContent/MediaGallery";
import FAQ from "./addContent/Faqs";
import PostsAndArticles from "./addContent/postsAndArticles";
import PatientEducation from "../HealthCareDept/addContent/patientEdu";
import { debounce } from "lodash";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TurndownService from 'turndown';
import axios from "axios";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import RearrangingDeptComponents from "./rearrangingDeptCompnts";




const contentList = ["Practitioners Groups", "HealthCare Services", "Posts & Articles", "Patient Education", "Media Gallery", "Frequently Asked Question"]
const ParticularHealthDepartmentInfo = () => {

    const userOrg = useSelector(state => state.registration.userOrg);
    const userOrgLoc = useSelector(state => state.registration.location);
    console.log(userOrgLoc, "locV")
    console.log(userOrg, "vupp")
    const [opneditHealthDept, setOpneditHealthDept] = useState(false);
    const [departmentInfo, setDepartmentInfo] = useState(null);
    const [selectedContent, setSelectedContent] = useState([]);
    const [showAddContent, setShowAddContent] = useState(false);
    const [openMenuItems, setOpenMenuItems] = useState(false);
    const [practitionerList, setPractitionerList] = useState([]);
    const [practitionerRoleList, setPractitionerRoleList] = useState([]);
    const [hover, setHover] = useState(false);
    const [switchTo, setSwitchTo] = useState("");
    const [showRearrangeDepartDialog, setShowRearrangeDepartDialog] = useState(false);


    const [about, setabout] = useState(false);
    const [editAbout, setEditAbout] = useState(false);
    // const [editorState, setEditorState] = useState(editorContent);
    const [showAbout, setShowAbout] = useState(false);

    const html = ``
    const blocksFromHTML = convertFromHTML(html)
    const content = ContentState.createFromBlockArray(blocksFromHTML)
    const editorContent = EditorState.createWithContent(content)


    const [editAboutText, setEditAboutText] = useState('');
    const [editorState, setEditorState] = useState(editorContent);
    const [markdownContent, setMarkdownContent] = useState('');







    let locObj = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    // let healthdeptInfo = locObj?.state?.resource;






    const healthDeptLoc = useSelector(state => state?.registration?.healthDeptLocationDetails);
    const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);

    const isAdmin = useSelector((state) => state?.registration?.isAdmin);
    console.log(healthDeptLoc, healthDeptOrg, "123")




    useEffect(() => {
        if (userOrg?.id) {
            D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: res.data
                })
            }).catch((err) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: null
                })
                console.log(err)
            })

        } else {
            dispatch({
                type: "IS_ADMIN",
                payload: null
            })
        }
    }, [userOrg?.id])

    useEffect(() => {
        setDepartmentInfo(locObj?.state?.resource)
    }, [locObj])

    useEffect(() => {
        setDepartmentInfo({ ...healthDeptOrg })
    }, [healthDeptOrg, healthDeptLoc])

    useEffect(() => {
        if (healthDeptOrg?.partOf?.reference?.split("/")?.[1]) {
            D_API.get(`${userOrg?.id}/${URI.organization}/${healthDeptOrg?.partOf?.reference?.split("/")?.[1]}`).then((res) => {
                dispatch(getUserOrg(res.data))
            }).catch((err) => console.log(err))
        }
    }, [healthDeptOrg])




    console.log(departmentInfo, "departmentInfo")


    useEffect(() => {
        D_API.get(`${userOrg?.id}/Organization/${id}`).then((res) => {
            dispatch(handleHealthDeptOrg(res.data));
            console.log(res.data)

        }).catch((err) => console.log(err));

        D_API.get(`${userOrg?.id}/${URI.getLocation}${id}`).then((res) => {
            console.log(res.data)
            let data = res.data?.entry?.[0]?.resource;
            dispatch(handleHealthDeptLoc(data))

        }).catch((err) => console.log(err))
    }, [id])



    useEffect(() => {

        // if (healthDeptLoc?.description) {
        //     if (about == false) {

        //         let filterData = healthDeptLoc?.description?.slice(0, 400);
        //         setEditAboutText(filterData)
        //     } else {
        //         setEditAboutText(healthDeptLoc?.description)
        //     }
        //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(healthDeptLoc?.description || `<p></p>`))))
        // }
        if (healthDeptLoc?.description) {
            setEditAboutText(healthDeptLoc?.description)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(healthDeptLoc?.description || `<p></p>`))))
        }

    }, [healthDeptLoc, about])


    console.log(editAboutText, "v1")



    useEffect(() => {
        if (userOrg?.id) {
            D_API.get(`${userOrg?.id}/${URI.getLocation}${userOrg?.id}`).then((res) => {
                let data = res.data?.entry?.[0]?.resource;
                dispatch({
                    type: "location",
                    payload: { ...data }
                })
                console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [userOrg?.id])


    useEffect(() => {
        if (healthDeptOrg?.id) {
            getAddedComponentsList();
        }
    }, [healthDeptOrg?.id])


    useEffect(() => {
        if (selectedContent?.length > 0) {
            addComponentsFn();
        }
    }, [selectedContent])




    const addComponentsFn = debounce(() => {
        if (selectedContent?.length > 0 && healthDeptOrg?.id) {
            DOC_API_WITHOUT_TOKEN.post(`${URI.addComponents}${healthDeptOrg?.id}`, [...selectedContent]).then((res) => {
                console.log(res.data)
                // getAddedComponentsList();
            }).catch((err) => console.log(err))
        }

    }, 2000);


    const getAddedComponentsList = () => {
        if (healthDeptOrg?.id) {
            DOC_API_WITHOUT_TOKEN.get(`${URI.getAddedComponents}${healthDeptOrg?.id}`).then((res) => {
                console.log(res.data, "get")
                let sortedList = res.data?.hashcomponents ? res.data?.hashcomponents.sort((a, b) => Number(a.id) - Number(b.id)) : []
                setSelectedContent(sortedList);
                // setSelectedContent(res.data?.hashcomponents ? res.data?.hashcomponents : [])
            }).catch((err) => console.log(err));
        }
    }

    console.log(selectedContent, "selectedContent")


    const handleAddContent = (value, idx) => {
        if (selectedContent?.map((e) => e?.name)?.includes(value)) {
            setOpenMenuItems(false);
            setShowAddContent(false);

        } else {
            setSelectedContent([...selectedContent, { id: idx, name: value }]);
            setOpenMenuItems(false);
            setShowAddContent(false);
        }

    }

    console.log(selectedContent, "selectedContent");


    const handleOpnHealthDept = () => {
        setOpneditHealthDept(true);
        setSwitchTo("")
    }

    const handleClsHealthDept = () => {
        setOpneditHealthDept(false);
    }


    const handleOpenAddContent = () => {
        setShowAddContent(true);
    };


    const handleOpenMenuItems = () => {
        setOpenMenuItems(!openMenuItems);
    };

    const handleMouseLeave = () => {
        setShowAddContent(false);
        setOpenMenuItems(false);
    };





    const editState = (editorState) => {
        setEditorState(editorState)
    }

    const editUpdate = async () => {

        let locationdata = { ...healthDeptLoc }

        locationdata.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        // console.log(locationdata, "locationdata")


        if (healthDeptLoc?.id) {
            D_API.put(`${userOrg?.id}/${URI.addLocation}/${healthDeptLoc?.id}`, locationdata).then(res => {
                // console.log(res.data, "02")
                D_API.get(`${userOrg?.id}/${URI.addLocation}/${res.data}`).then(res => {
                    dispatch(handleHealthDeptLoc(res.data));
                    setEditAbout(false);
                }).catch((err) => alert(err));

            }).catch((err) => {
                setEditAbout(false);
                console.log(err)
            })
        } else {
            setEditAbout(false);
        }

    }



    const handleClose = () => {
        setEditAbout(false)
    }

    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data1 = reader.result.split(",")[1];

                // const base64Data = reader.result.replace("data:image/png;base64,", "");
                const type = file.type.split("/")[1];
                console.log(base64Data1, "basebase")
                const payload = {
                    file: [base64Data1],
                    fileType: type,
                    multiple: false,
                    subType: "profilePic",
                    type: "userProfilePics"
                };

                axios.post(`${URL.uploadFile}`, payload, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {

                    console.log(response, "rereddss")
                    resolve({ data: { link: response.data?.data?.[0] } });
                }).catch(error => {
                    reject(error);
                });
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };



    const handleOpnRearrangeDepartDialog = () => {
        setShowRearrangeDepartDialog(true);
    }

    const handleClsRearrangeDepartDialog = () => {
        setShowRearrangeDepartDialog(false);
    }


    const renderComponents = (list) => (
        list.map((val) => {
            switch (val.name) {
                case "Practitioners Groups":
                    return <PractitionerGroup />
                case "HealthCare Services":
                    return <SubHealthServices />
                case "Posts & Articles":
                    return <PostsAndArticles />
                case "Media Gallery":
                    return <MediaGallery openEditDialogDept={setOpneditHealthDept} changeTab={setSwitchTo} />
                case "Frequently Asked Question":
                    return <FAQ healthCare={departmentInfo} />
                case "Patient Education":
                    return <PatientEducation />

                default: return;
            }
        })
    );


    return (
        <div style={{ paddingTop: "10px" }}>

            <ValidatorForm onSubmit={() => ""}>

                <EditHealthCareDept opn={opneditHealthDept} cls={handleClsHealthDept} id={departmentInfo?.id} switchToTab={switchTo} />
                <Card sx={{ padding: "10px", height: "60px" }}>
                    <Grid container spacing={1} alignItems="center">
                        {/* <Grid item xs={1} sm={1} md={1}>
  <ArrowBackIcon onClick={() => { history.push(`/admin/organization/${userOrg?.identifier?.[1]?.value}`) }} />
                        </Grid> */}
                        <Grid item xs={11} sm={11} md={11}>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <div className="cursor">
                                    <ArrowBackIcon onClick={() => { history.push(`/admin/organization/${userOrg?.identifier?.[1]?.value}`) }} />
                                </div>
                                <div>
                                    <Avatar variant="square" sx={{

                                        // width: '30px !important', height: '30px !important',
                                        // backgroundColor: "", boxShadow:
                                        //     "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", border: "0.5px solid #fff",
                                    }} src={departmentInfo?.identifier?.filter((info) => info["system"]?.includes("logo") && info["value"] && info["value"]?.startsWith("https"))?.[0]?.value || DefaultHealthDeptIcon} />
                                    {/* <img src={healthdeptInfo?.identifier?.filter((info) => info["system"]?.includes("logo") && info["value"])?.[0]?.value || OrgLogo} style={{ width: "30px", height: "30px" }} /> */}
                                </div>
                                <div>
                                    <div className="font-head fntSemiBold capitalize">{departmentInfo?.name}</div>
                                    <div className="fntSm capitalize">{departmentInfo?.alias?.[0]}</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            {
                                isAdmin == "PERMIT" &&
                                <div className="flx-center justify-between">
                                    {
                                        selectedContent.length >= 2 &&
                                        <div>
                                            <MenuIcon className="cursor" sx={{ width: "25px", height: "25px", color: "#197FC4", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }} onClick={handleOpnRearrangeDepartDialog} />
                                        </div>
                                    }

                                    <div>
                                        <img width="18px" height="18px" className="cursor" src={Settings} onClick={handleOpnHealthDept}></img>
                                    </div>
                                </div>
                            }

                        </Grid>
                    </Grid>
                    {/* <Grid container>
                        <Grid item xs={1} sm={1} md={1}>

                        </Grid>
                        <Grid item xs={10} sm={10} md={10}>
                            <div>
                                <p className="pv1 fntSm">{healthDeptLoc?.description}</p>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                        </Grid>
                    </Grid> */}
                </Card>




                <div className="mv1" >
                    <div sx={{}}>
                        <Card >
                            <div style={{ padding: '10px' }}>
                                <div style={{ padding: '0px 8px' }}>
                                    <div className="font-head fntSemiBold flx-center">About &nbsp;&nbsp;
                                        {
                                            isAdmin == "PERMIT" &&
                                            <>
                                                {
                                                    healthDeptLoc?.id ?
                                                        <EditIcon
                                                            sx={{ cursor: "pointer", paddingTop: "4px" }}
                                                            onClick={() => {
                                                                setEditAbout(!editAbout);
                                                                setEditAboutText(healthDeptLoc?.description);

                                                            }}
                                                            fontSize={"small"}
                                                        />
                                                        :
                                                        <Tooltip title="Complete your healthDepartment profile form" arrow>
                                                            <EditIcon
                                                                sx={{ cursor: "pointer", }}
                                                                fontSize={"small"}
                                                                onClick={() => {
                                                                    if (healthDeptOrg?.id) {
                                                                        setOpneditHealthDept(true);
                                                                        setSwitchTo("2")

                                                                    }

                                                                }}
                                                            />
                                                        </Tooltip>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>

                                {editAbout && (
                                    <Dialog
                                        // fullWidth="lg"
                                        maxWidth={"md"}
                                        open={editAbout}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"About Hospital"}
                                        </DialogTitle>
                                        <DialogContent sx={{ padding: '2px 5px' }}>
                                            <Editor
                                                editorStyle={{
                                                    overflow: 'auto',
                                                    height: '260px',
                                                }}
                                                toolbar={{
                                                    // inline: { inDropdown: true },
                                                    // list: { inDropdown: true },
                                                    // textAlign: { inDropdown: true },
                                                    // link: { inDropdown: true },
                                                    // history: { inDropdown: true },
                                                    image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                                                }}
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={(editorState) => editState(editorState)}
                                            />
                                        </DialogContent>
                                        <DialogActions >
                                            <Button
                                                fontSize="small"
                                                // sx={{ float: 'right', p: "", m: "" }}
                                                variant="outlined"
                                                color="error"
                                                onClick={handleClose}
                                                className="capitalize"
                                            >
                                                cancel
                                            </Button>
                                            <Button
                                                fontSize="small"
                                                sx={{ float: 'right', p: 1, m: 1 }}
                                                variant="contained"
                                                className="capitalize"
                                                disabled={editAboutText?.length == 0}
                                                onClick={editUpdate}
                                            >
                                                Update
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}


                                {!editAbout && healthDeptLoc?.description && (
                                    <Typography my={1} sx={{ padding: '0px 8px' }}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: editAboutText }}
                                            // style={{ whiteSpace: 'pre-wrap' }}
                                            style={{
                                                display: !showAbout ? '-webkit-box' : "",
                                                WebkitBoxOrient: 'vertical ',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                lineswhiteSpace: 'normal',
                                                whiteSpace: 'pre-wrap'
                                            }}

                                        />


                                        {/* <ReactMarkdown>{htmlToMarkdown(editAboutText || "")}</ReactMarkdown> */}
                                    </Typography>
                                )}



                                {/*                         
                        {
                            healthDeptLoc?.description &&
                            (
                                <p className="fntSm">
                                    {showAbout
                                        ? healthDeptLoc?.description
                                        : `${healthDeptLoc?.description?.slice(0, 400)} ${healthDeptLoc?.description?.length > 400 ? "..." : ""
                                        }`} <span>  {healthDeptLoc?.description?.length > 400 && (
                                            <Button
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding: "0 !important",
                                                    paddingLeft: "10px !important",
                                                    color: "#000",
                                                    fontWeight: "600",
                                                }}
                                                onClick={() => setShowAbout(!showAbout)}
                                            >
                                                {showAbout ? "Learn less" : "Learn more"}
                                            </Button>
                                        )}
                                    </span>
                                </p>
                            )
                        } */}
                            </div>
                            <Divider />
                            {healthDeptLoc?.description?.length > 400 &&
                                <div
                                    style={{ padding: '8px', cursor: 'pointer', fontWeight: 'bold', background: hover ? "#F4F2EE" : "" }}
                                    onClick={() => setabout(!about)}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                >
                                    {
                                        !showAbout ?
                                            <div className='flx-center justify-center' onClick={() => setShowAbout(true)} >
                                                <div  >
                                                    Show More
                                                </div>
                                                <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                            </div>
                                            :
                                            <div className='flx-center justify-center' onClick={() => setShowAbout(false)}>
                                                <div > Show Less </div>
                                                <KeyboardArrowUp style={{ marginTop: '3px' }} />
                                            </div>

                                    }
                                    {/* {editAboutText?.length > 400 ?
                                        <div className='flx-center justify-center '>
                                            <div> Show less </div>
                                            <KeyboardArrowUp style={{ marginTop: '3px' }} /></div>
                                        : <div className='flx-center justify-center'>
                                            <div >
                                                Show More
                                            </div>
                                            <KeyboardArrowDown style={{ marginTop: '3px' }} />
                                        </div>} */}
                                </div>
                            }
                        </Card>
                    </div>
                </div>


                <div>{renderComponents(selectedContent)}</div>

                {/* 

                {
                    selectedContent?.map((e) => e?.name).includes("Practitioners Groups") && <PractitionerGroup />

                }

                {
                    selectedContent?.map((e) => e?.name).includes("HealthCare Services") && <SubHealthServices />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Posts & Articles") && <PostsAndArticles />
                }

                {
                    selectedContent?.map((e) => e?.name).includes("Media Gallery") && <MediaGallery openEditDialogDept={setOpneditHealthDept} changeTab={setSwitchTo}
                    />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Frequently Asked Question") && <FAQ healthCare={departmentInfo} />
                }
                {
                    selectedContent?.map((e) => e?.name).includes("Patient Education") && <PatientEducation />
                } */}



                {/* 
                {
                    selectedContent.length > 0 && selectedContent.map((e, i) => {
                        return (
                            <div className="mv1" key={i}>
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <div className="fntMd fntSemiBold">
                                                {e}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <div>
                                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Card>

                            </div>
                        )
                    })
                } */}

                {/* <div className="mv1 flx-center " style={{ width: "0px", backgroundColor: "red" }} onMouseEnter={() => {
                    setShowAddContent(true);

                }} onMouseLeave={() => setShowAddContent(false)} >


                    <span><AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" /></span>

                    {
                        showAddContent &&
                        <span>
                            <OrgLayout
                                // label="Search By Organisation Name *"
                                sx={{ width: "300px" }}
                                name="selectedContent"
                                input="select"
                                options={contentList || []}
                                value={selectedContent}
                                // getOptionLabel={(option) => option.name || ""}
                                // onInputChange={handleSearchInputOfLoc}
                                handleChange={handleAddContent} // Corrected prop name
                                placeholder="Add Content"
                            // validators={locationInfo?.resource?.id ? [] : ["required"]}
                            // errorMessages={["required field"]}
                            />
                        </span>
                    }




                </div> */}
                {/* <div style={{ width: "150px" }}   >
                    <div style={{ width: "100%", height: "30px", border: showAddContent ? "1px solid grey" : "", borderRadius: showAddContent ? "" : "", paddingLeft: "5px", paddingTop: "5px" }}
                        onMouseEnter={handleOpenAddContent} onMouseLeave={handleCloseAddContent}
                    >
                        <div>

                            <div style={{ width: "100%", display: "flex", alignItems: "", justifyContent: "", }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", paddingTop: "3px" }} className="cursor" onClick={handleOpnMenuItems} />
                                <div style={{ display: "flex", alignItem: "center", justifyContent: "space-between", }}>
                                    <div style={{ display: showAddContent ? "block" : "none" }}> Add Content </div>
                                    <div style={{ display: showAddContent ? "block" : "none" }}> <KeyboardArrowDownIcon sx={{ height: "20px", paddingTop: "2px" }} /></div>
                                </div>
                            </div>
                        </div >

                    </div>
                    {
                        openMenuItems &&
                        <div style={{ width: "100%", border: "1px solid grey", paddingLeft: "5px", borderRadius: showAddContent ? "" : "", }}>
                            <div>1</div>
                            <div>2</div>
                            <div>3</div>
                            <div>4</div>
                        </div>
                    }
                </div> */}


                {
                    isAdmin === "PERMIT" &&
                    <div style={{ width: "280px", cursor: "pointer" }} className="mv1" onMouseLeave={handleMouseLeave} onClick={handleOpenMenuItems}>
                        <div
                            style={{
                                boxShadow: showAddContent ? "2px 3px 10px lightgrey" : "",
                                backgroundColor: "#ffffff",
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                height: "30px",
                                // border: showAddContent ? "1px solid grey" : "",
                                borderRadius: showAddContent ? "" : "",
                                paddingTop: "5px"
                            }}
                            onMouseEnter={handleOpenAddContent}
                        >
                            <div style={{ display: "flex", justifyContent: showAddContent ? "space-between" : "", paddingRight: "5px", width: "100%", paddingLeft: "5px", borderRadius: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <div>
                                        <AddCircleIcon
                                            fontSize="small"
                                            sx={{ color: "#008114", paddingTop: "3px" }}
                                            className="cursor"
                                        // onClick={handleOpenMenuItems}
                                        />
                                    </div>
                                    <div>
                                        <div style={{ display: showAddContent ? "block" : "none", fontSize: "15px" }}> &nbsp; {openMenuItems ? "Select Content Type" : "Add Content"} </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: showAddContent ? "block" : "none" }}> <KeyboardArrowDownIcon sx={{ height: "20px", paddingTop: "2px" }} /></div>
                                </div>
                            </div>
                        </div>
                        {openMenuItems && (

                            <div style={{ width: "100%", paddingLeft: "", borderRadius: showAddContent ? "" : "", backgroundColor: "#ffffff", boxShadow: showAddContent ? "2px 3px 10px lightgrey" : "", }}>
                                {
                                    contentList?.map((e, i) => {
                                        return (
                                            <div className="menuHoverEffect" style={{ padding: "5px", cursor: "pointer", }} onClick={() => handleAddContent(e, i)}>{e}</div>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div >
                }


                <RearrangingDeptComponents open={showRearrangeDepartDialog} close={handleClsRearrangeDepartDialog} componentsList={selectedContent} updateGetList={setSelectedContent} healthDeptId={healthDeptOrg?.id} />
            </ValidatorForm >
        </div >
    )
}
export default ParticularHealthDepartmentInfo;