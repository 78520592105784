import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import './Post.css';
import { Avatar, Typography, Box, Button } from "@mui/material";
import { ReactComponent as More } from '../../../images/more.svg'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Photo from '../feedposts/photo'
import axios from 'axios'
import URL from '../../apiurl'
import Delete from '@mui/icons-material/Delete';
import { useRouteMatch, useHistory } from 'react-router';
import Poll from '../feedposts/poll/pollPost'
import DialogBox from '../../DialogBox/dialog'
import PublicIcon from '@mui/icons-material/Public';
import Peers from '../../Atom/svg/peers.svg';
import Doc from '../../../images/Logo2.svg'
import Event from '../feedposts/events/eventPost'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ArticleView from '../../Article/articlepost';
import Bookmark from '../../../icons/bookmark.svg'
import Bookmarked from '../../../icons/bookmarkfill.svg'
import { doRefresh, getMetaData } from '../../../redux/Actions/actions';
import CreatePost from '../Feed/createpost';
import share from "../../../images/open_external.svg";
import { MetaView, timeSince, Actions, Description, BootstrapTooltip, postAnalytics } from './commonFun';
import PdfView from './PdfView';
import DisplayAcc from '../../Layout/MyProfile/organisation/DisplayAcc';
import Postuserdata from './Postuserdata';
import { headers } from '../../../redux/Actions/connectActions';
import { getPostById as getPost } from '../../../redux/Actions/actions'
import UserService from '../../../services/UserService';
import handleViewport from 'react-in-viewport'
// import Observer from "@researchgate/react-intersection-observer";

function Post(props) {

    const [metatags, setMetatags] = useState({})
    const [meta, setMeta] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [video, setVideo] = useState('')
    // const [post, setPost] = React.useState(props.post)
    const [post, setPost] = React.useState(null);
    const [modal, setModal] = React.useState({ open: false, id: '' })
    const [pDelete, setPDelete] = React.useState(false)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [shareEdit, setShareEdit] = useState(true)
    const [bookmarked, setBookmarked] = useState(false)
    console.log(props.post, "kumar")
    console.log(shareEdit, "share");
    console.log(post, "vinay")

    const { inViewport, forwardedRef } = props;
    const { path } = useRouteMatch();
    const history = useHistory()
    const state = useSelector(state => state.userProfile.userProfile)
    const open = Boolean(anchorEl);
    const videoRef = useRef(null);

    const dispatch = useDispatch()

    let id = post?.id
    let myUserid = parseInt(sessionStorage.getItem('userId'));

    console.log(myUserid, "myUserid")

    const pid = (post) ? post.userId : "";
    const receiverimage = state.hasOwnProperty("practioner") ? state.practioner?.photo?.[0]?.url : ""
    const userID = state.id;
    const username = state.fullName;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlesharebutton = () => {
        setShareEdit({ open: true, name: 'share' })
    }

    useEffect(() => {
        setBookmarked(props.post.bookMarked)
    }, [])

    useEffect(() => {
        setPost(props.post)
    }, [props.post])

    const getPostById = async (id) => {
        let pdata = await getPost(id || post.id)
        setPost(pdata)
    }

    const handlelinkcopy = () => {
        // navigator.clipboard.writeText(`http://localhost:3001/post/${id}`)
        navigator.clipboard.writeText(`${process.env.REACT_APP_SHARE_POST}/${id}`)
    }

    const deletePost = async () => {
        await axios.delete(`${URL.deletePost}/${post.id}`, headers()).then(res => {
            // props.getPosts()
            deletePostCache()
            dispatch(doRefresh("refreshFeed"))
        }).catch(err => props.getPosts())
        setPDelete(false)
    }

    const deletePostCache = () => {
        let data = {
            update: "delete_post",
            post_id: post.id,
            new_post: post

        }
        UpdateChacheFeed(data)
    }

    const addCollection = async () => {
        setBookmarked(true)
        let data = {
            "metadata": await getMetaData(),
            "postId": post.id,
            "userId": parseInt(sessionStorage.getItem('userId'))
        }
        axios.post(`${URL.addCollection}`, data, headers()).then(res => {
            getPostById()
            postAnalytics(post, 'addInteraction')
            CollectionCache("add_bookmark", post.id)
        })
    }

    const CollectionCache = async (type, POSTID) => {
        // console.log(type, POSTID, 'aaa')
        let bookMarkId = 0;
        //getpostbyid
        await axios.get(`${URL.postById}/${POSTID}`, headers()).then(res => {
            bookMarkId = res.data.bookMarkId
        }).catch(err => {
            console.log(err)
        })

        let data = {
            update: type,
            post_id: POSTID,
            bookmark_id: bookMarkId ? bookMarkId : 0
        }
        UpdateChacheFeed(data)
    }

    const UpdateChacheFeed = async (data) => {
        await axios.post(`${URL.updateChacheFeed}`, data, headers())
            .then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
    }

    const deleteCollection = (id) => {
        setBookmarked(false)
        axios.delete(`${URL.deleteCollection}/${id}`, headers()).then(res => {
            if (props.getBookmarks) {
                props.getBookmarks()
                // CollectionCache("delete_bookmark", post.id)
            }
            else {
                getPostById()
                // CollectionCache("delete_bookmark", post.id)
            }
            CollectionCache("delete_bookmark", post.id)
        })
    }

    useEffect(() => {
        if (post?.description) {
            const url = post?.description?.split(" ")
            const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
            let val1 = regex.test(url[url?.length - 2])
            if (val1 === true) {
                // axios.post('http://localhost:3001/scrapUrl', { url: url[i] }).then(res => {
                axios.post(`${process.env.REACT_APP_API_SCRAPE}`, { url: url[url.length - 2] }).then(res => {
                    if (res.data !== null) {
                        setMetatags(res.data.og)
                        setMeta(res.data.meta)
                    }
                })
            }
            else {
                setMetatags({})
                setMeta({})
            }
        }
    }, [post?.description])


    const handleEdit = (post) => {
        if (post.shared) {
            setShareEdit({ open: true, name: 'shareEdit' })
        }
        else {
            setShareEdit({ open: true, name: 'edit' })
        }

    }

    const showhashtag = (tag) => {
        history.push({
            pathname: `/hashtagpost`,
            state: tag
        })
    }

    const editPost = (post) => {
        if (post.postType === 'ARTICLE' && !post.shared) {
            history.push({
                pathname: `/editarticle/${post.articleData.articleTitle}/${post.id}`
            })
            document.location.reload()
        } else {
            handleEdit(post);
            handleClose()
        }
    }

    const getvideo = () => {
        if (post?.postType === 'VIDEO') {
            let data = post?.sourceLink?.split(',');
            let videodata = data?.slice(0, -1)
            setVideo(videodata)
        }
    }

    useEffect(() => {
        getvideo()
    }, [])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (inViewport) {
                console.log("inviewport", post?.postType)
                postAnalytics(post, 'addImpression')
            }
        }, 5000)
        return () => clearTimeout(timeoutId);

    }, [inViewport])

    useEffect(() => {
        let options = {
            rootMargin: "0px",
            threshold: [0.25, 0.75]
        };

        setTimeout(() => {
            let handlePlay = (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // videoRef.current.play();
                    } else {
                        if (videoRef && videoRef !== null) {
                            videoRef.current.pause();
                        }
                    }
                });
            };
            let observer = new IntersectionObserver(handlePlay, options);
            if (videoRef !== null) {
                // observer?.observe(videoRef?.current);
            }
        }, 3000)

    }, [videoRef]);

    return (
        <div className="post" style={{ width: props.fromAdminOrg == "adminOrg" ? "100%" : "", height: props.fromAdminOrg == "adminOrg" ? "" : "", }} >
            {shareEdit?.open ? <CreatePost postdata={post} handleOpen={shareEdit.name} handleClose={() => { getPostById(post?.id); setShareEdit({ open: false, name: '' }) }} /> : ''}

            <DialogBox open={pDelete} handleDialog={() => setPDelete(false)} Content={<p>Do you want to delete post?</p>}
                Actions={<div>
                    <Button variant="contained" tabIndex={1} className="add-option" sx={{ mr: 2 }} onClick={() => deletePost()}>Yes</Button>
                    <Button variant="contained" tabIndex={2} className="add-option" onClick={() => { setPDelete(false); handleClose() }}>No</Button>
                </div>}
            />

            {post?.likeCount > 0 ? <DialogBox width="xs" open={openDialog} handleDialog={() => setOpenDialog(false)}
                Content={<Postuserdata title="Post Likes" data={post?.id} closeDialog={() => setOpenDialog(false)} />
                }
            /> : ''}
            <div className="post__header">
                {(post?.userId !== 0) ?
                    <Avatar src={props.display ? post?.sharedData?.profilePic : post?.userData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} /> :
                    <Avatar src={props.display ? post?.sharedData?.profilePic : post?.pageData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} />
                }
                <div className="post__info">
                    {props?.display ?

                        <div>
                            <h2 className="cursor capitalize"><span onClick={() => post?.userId !== 0 ? history.push(`/${(post?.sharedData?.name?.replace(/ /g, '')).toLowerCase()}-${post?.sharedData?.sharedFromUserId}`) : history.push(`/organization/${post?.sharedData?.sharedFromPagesId}`)}>{post?.sharedData?.name} </span></h2>
                            <p className="fontItalic" >{post.sharedData.captionAndTagline}</p>
                            <Typography className="fabdetail e-align">
                                <span>{`${timeSince(moment.utc(post.sharedData.createDate).local())}`}</span>&nbsp;
                            </Typography>
                        </div>
                        :
                        <div>
                            <h2 className="cursor capitalize" ><span onClick={() => post?.userId !== 0 ? history.push(
                                post?.userId === myUserid ?
                                    `/myprofile/${(post?.userData?.fullName?.replace(/ /g, ''))?.toLowerCase()}-${post?.userId}`
                                    :
                                    `/profile/${(post?.userData?.fullName?.replace(/ /g, ''))?.toLowerCase()}-${post?.userId}`
                            ) : history.push(`/organization/${post?.pageId}`)}>{(post?.userId !== 0) ? post?.userData?.fullName : post?.pageData?.fullName}</span>
                                <span style={{ textTransform: 'initial', color: '#635660', fontWeight: 'normal', cursor: 'text' }}>
                                    {post?.shared ? ' shared a post' : ''}
                                </span></h2>
                            <p className="fontItalic" >{(post?.userId !== 0) ? post?.userData?.caption : post?.pageData?.caption}</p>
                            <Typography className="fabdetail e-align ">
                                <span>{`${timeSince(moment.utc(post?.createDate).local())}`}</span>&nbsp;
                                {post?.postPrivacy === 'ANYONE' ? <PublicIcon className='font-title' style={{ fontSize: '12px' }} /> : post?.postPrivacy === 'DOCSTETH_USERS' ? <img src={Doc} alt="DOCSTETH USERS" width="22px" /> : <img src={Peers} alt="Peers" width="30px" />}
                            </Typography>
                        </div>

                    }
                    {props?.share ? '' :
                        // className="post-left"

                        <div className="flex" style={{ display: "flex", alignItems: "" }} >
                            {/* {props.bookmark !== true && (path.includes('/myprofile') || path.includes('/admin')) ? '' : */}
                            <div className="cursor pt-05">
                                {bookmarked ?
                                    <BootstrapTooltip title='Unsave'>
                                        <img src={Bookmarked} className='bookmark_img' alt="bookmarked" onClick={() => deleteCollection(post?.bookMarkId)} />
                                    </BootstrapTooltip> :
                                    <BootstrapTooltip title='Save'>
                                        <img src={Bookmark} className='bookmark_img' alt="bookmark" onClick={() => addCollection()} />
                                    </BootstrapTooltip>}
                            </div>
                            {/* <img src={More} alt="More" width="18px" style={{marginLeft: '20px'}}/> */}
                            <div>
                                {/* <BootstrapTooltip title='More' sx={{ ml: 2, mr: 2, alignItems: 'start' }}> */}
                                <BootstrapTooltip title='More' sx={{ ml: 2, mr: 2, alignItems: 'start' }}>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls="long-menu"
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        sx={{ ml: 2, mr: 2, alignItems: 'start' }}
                                    >
                                        <More width="20px" />
                                    </IconButton>
                                </BootstrapTooltip>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            width: '15ch',
                                        },
                                    }}
                                >

                                    {path?.includes('/myprofile') || path?.includes('/admin') || path === '/' ?
                                        !props?.bookmark && <span>
                                            {((path === '/' && post?.userId === Number(UserService.getUserId())) || (path !== '/')) &&
                                                <>
                                                    {((post?.postType === 'POLL') && !post?.shared) || (post?.postType === 'EVENT' && new Date(moment.utc(post?.eventData?.openingTime)) < new Date()) ? '' :
                                                        <MenuItem onClick={() => editPost(post)}>
                                                            <EditIcon style={{ position: "relative", color: "#007dcd" }} /> &nbsp;&nbsp;Edit
                                                        </MenuItem>}
                                                    <MenuItem onClick={() => setPDelete(true)}>
                                                        <Delete sx={{ color: "#007dcd" }} /> &nbsp;&nbsp;Delete
                                                    </MenuItem>
                                                </>
                                            }
                                        </span> : ''}
                                    <MenuItem onClick={handleClose}>
                                        <ReportGmailerrorredIcon sx={{ color: "#007dcd" }} /> &nbsp;&nbsp;Report
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <span onClick={handlelinkcopy} style={{ display: "flex", justifyContent: "center", alignContent: "center" }}><img src={share} />&nbsp;&nbsp;CopyLink</span>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>}
                </div>
            </div>
            {props?.display ? '' : <>
                {post?.sharedDescription ?
                    post?.sharedDescription !== 'NA' && post?.sharedDescription !== null ?
                        <>
                            <Description description={post?.sharedDescription} />
                        </>
                        : '' : ''}

                {post?.sharedTags ? post?.sharedTags?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" && <span style={{ paddingLeft: "10px" }} key={i} onClick={() => showhashtag(tag)}><Typography component="span" className="name_hover b-color cursor">{tag}</Typography>&nbsp;</span>) : ''}

            </>}
            <div className={`${post?.shared && !props?.display ? 'shared-styles' : ''}`}>
                {post?.shared && !props?.display ?
                    <div className="post__header">
                        <Avatar src={post?.sharedData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important" }} />
                        <div className="post__info">
                            <div>
                                <h2 onClick={() => post?.sharedData?.sharedFromUserId ? history.push(`/${(post?.sharedData?.name?.replace(/ /g, '')).toLowerCase()}-${post?.sharedData?.sharedFromUserId}`) : history.push(`/organization/${post?.sharedData?.sharedFromPagesId}`)} className="cursor capitalize">{post?.sharedData?.name}</h2>
                                <p className="fontItalic">{post?.sharedData?.captionAndTagline}</p>
                                <Typography className="fabdetail e-align">
                                    <span>{`${timeSince(moment.utc(post?.sharedData?.createDate).local())}`}</span>&nbsp;
                                </Typography>
                            </div>
                        </div>
                    </div> : ''}
                <div className="post__body" ref={forwardedRef}>
                    <Box sx={{ wordBreak: 'break-all' }}>
                        {post?.content ?
                            <Typography varaint="overline" sx={{ color: '#635660', padding: "0px 10px" }}>
                                {post?.content}
                            </Typography> : ''}
                        {post?.description && <Description description={post?.description} />}
                        {/* Description({post.description})</BootstrapTooltip> */}
                        <div style={{ margin: "0px 10px" }}>
                            {post?.tags ?
                                post?.tags?.replace(/,/g, ' ')?.split(' ')?.map((tag, i) => tag !== "null" &&
                                    <span key={i}>
                                        <BootstrapTooltip title={`${tag}`}>
                                            <span onClick={() => showhashtag(tag)} key={i} style={{ paddingLeft: "8px" }}>
                                                <Typography component="span" className="name_hover b-color cursor">{tag}</Typography>&nbsp;
                                            </span>
                                        </BootstrapTooltip>
                                    </span>
                                ) : ''
                            }
                        </div>
                    </Box>
                </div>
                {console.log(post, 'sdfasgasgsadre', meta)}
                <Box style={{ pointerEvents: props?.share ? 'none' : 'all', width: "100%", height: "100%" }}>

                    {post?.postType !== 'TEXT' && post?.postType !== 'CELEBRATE' ?

                        post?.postType === "IMAGE" ? post?.sourceLink !== null && <Photo url={post?.sourceLink?.split(',')?.slice(0, -1)} post={post} getPostById={getPostById} />

                            : post?.postType === "POLL" ? <Poll post={post} getPostById={getPostById} border={props?.share ? true : false} />

                                : post?.postType === 'FILE' ? post?.sourceLink !== null &&
                                    // <iframe height="400px" width="100%" src={`${post.sourceLink[0]}#toolbar=0&navpanes=0&scrollbar=0`} scrolling="no" frameBorder="0" />
                                    <PdfView data={`${post?.sourceLink}`} stateview={true}></PdfView>
                                    : post?.postType === 'VIDEO' ? post?.sourceLink !== null && <video style={{ backgroundColor: 'black' }} width="100%" height="400" src={video} controlsList="nodownload" controls ref={videoRef} />

                                        : post?.postType === 'EVENT' ? <Event post={post} getPostById={getPostById} />

                                            : post?.postType === 'ARTICLE' ? <ArticleView post={post} handlesharebutton={handlesharebutton} /> : ''

                        : metatags?.title || meta?.title ? <MetaView metatags={metatags} meta={meta} />
                            : post?.postType === 'CELEBRATE' && post?.accomplishmentData && post?.accomplishmentData?.accomplishmentId !== 0 ? <DisplayAcc badges={post?.accomplishmentData} />
                                : ""}
                </Box>
            </div >
            {props?.share ? '' : <Actions post={post} getPostById={getPostById} modal={(id) => setModal({ open: !modal.open, id: id })} handlesharebutton={handlesharebutton} openDialog={() => setOpenDialog(true)} userID={userID} pid={pid} username={username} receiverimage={receiverimage} />}
        </div >
    )
}

const ViewportBlock = handleViewport(Post, { rootMargin: '0% 0% -15%' });

const Component = (props) => {
    return (
        <div>

            <ViewportBlock {...props} onEnterViewport={() => console.log('enter')} onLeaveViewport={() => console.log('leave')} />
        </div>
    )
}
const MemoCom = React.memo(Component)
export default MemoCom
