import React, { useState, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Table from '@editorjs/table';
import edjsHTML from 'editorjs-html';
import axios from 'axios';
import { headers } from '../../redux/Actions/connectActions';
import URL from '../apiurl';

const ReactEditorJS = createReactEditorJS();

const EditorComponent1 = () => {
    const editorRef = useRef(null);
    const [check, setCheck] = useState('');




    // Parser functions
    const headerParser = (block) => {
        return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
    };

    const paragraphParser = (block) => {
        return `<p>${block.data.text}</p>`;
    };

    const linkToolParser = (block) => {
        const { link, meta } = block.data;
        const title = meta?.title || 'Link';
        return `<a href="${link}" target="_blank" rel="noopener noreferrer">${title}</a>`;
    };

    const tableParser = (block) => {
        const rows = block.data.content.map(row => {
            const cells = row.map(cell => `<td>${cell}</td>`).join('');
            return `<tr>${cells}</tr>`;
        }).join('');
        return `<table>${rows}</table>`;
    };

    const listParser = (block) => {
        const items = block.data.items.map(item => `<li>${item}</li>`).join('');
        return `<ul>${items}</ul>`;
    };

    const checklistParser = (block) => {
        const items = block.data.items.map(item => {
            return `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`;
        }).join('');
        return `<ul>${items}</ul>`;
    };

    // Define parserOptions
    const parserOptions = {
        header: headerParser,
        paragraph: paragraphParser,
        linkTool: linkToolParser,
        table: tableParser,
        list: listParser,
        checklist: checklistParser,
    };
    const handleSave = async () => {
        let res;
        try {
            res = await editorRef.current.saver.save();
            console.log(res, "res");
        } catch (error) {
            console.error("Error saving:", error);
            return;
        }

        // Check if blocks are present and valid
        if (!res?.blocks || !Array.isArray(res.blocks)) {
            console.error("No valid blocks found.");
            return;
        }

        // Log the structure of res.blocks for debugging
        console.log(res.blocks, "Parsed blocks before conversion");

        const edjsParser = edjsHTML();

        try {
            // Check each block's structure
            // res.blocks.forEach((block, index) => {
            //     console.log(`Block ${index}:`, block);
            //     if (!block.data) {
            //         console.warn(`Block ${index} does not have data:`, block);
            //     }
            // });
            const parsedHtml = edjsParser.parse(res);
            console.log(parsedHtml, "Parsed HTML");

            const convertIntoHtml = parsedHtml.join('');
            setCheck(convertIntoHtml);
        } catch (error) {
            console.error("Error parsing blocks:", error);
        }
    };

    console.log(check, "check");

    const handleOnChange = (outputData) => {
        editorRef.current = outputData;
    };

    const toconvertImgtoimgUrl = async (imgUrl) => {
        if (!imgUrl) {
            return { success: 0, message: "No image provided" };
        }

        let image = imgUrl.split(',')[1];
        let type = imgUrl.split(',')[0].split('/')[1].split(';')[0];
        let base64 = imgUrl.split(",")[1].split("=")[0];
        let strLength = base64.length;
        let fileLength = strLength - (strLength / 8) * 2;

        let data = {
            file_type: 'IMAGE',
            files: [
                {
                    file_size: Number((fileLength / 1048576).toFixed(3)),
                    file_extension: type,
                    data: image.toString(),
                },
            ],
        };

        try {
            const res = await axios.post(`${URL.upload}`, data, headers());
            console.log(res, "after");

            if (res.status === 200) {
                return {
                    success: 1,
                    file: {
                        url: res.data?.[0], // Adjust based on your actual response structure
                    },
                };
            } else {
                return {
                    success: 0,
                    message: "Upload failed",
                };
            }
        } catch (error) {
            console.error("API error:", error);
            return {
                success: 0,
                message: "Upload failed",
            };
        }
    };




    const uploadByFile = async (file) => {
        console.log(file, "file")
        let reader = new FileReader()
        reader.readAsDataURL(file);
        console.log(reader.readAsDataURL, "RESULT")
        reader.onloadend = () => {
            toconvertImgtoimgUrl(reader.result)
        }

    }

    const uploadByUrl = async (file) => {
        return file;
    };

    return (
        <>
            <ReactEditorJS
                ref={editorRef}
                onChange={handleOnChange}
                tools={{
                    header: {
                        class: Header,
                        inlineToolbar: ['link'],
                    },
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                    },
                    list: {
                        class: List,
                        inlineToolbar: true,
                    },
                    checklist: {
                        class: Checklist,
                        inlineToolbar: true,
                    },
                    linkTool: LinkTool,
                    image: {
                        class: ImageTool,
                        config: {
                            uploader: {
                                uploadByFile,
                                uploadByUrl
                            },
                        },
                    },
                    table: Table,
                }}
            />

            <h2>After converting into string format</h2>
            <div
                dangerouslySetInnerHTML={{ __html: check }}
                style={{
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    whiteSpace: 'pre-wrap',
                }}
            />
            <button onClick={handleSave}>Save</button>
        </>
    );
};

export default EditorComponent1;
