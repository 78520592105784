import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Edit from '../../../../images/edit1.svg'
import StethscopeImg from '../../../../images/stethoscope1.svg'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddServices from './AddServices';
import { useDispatch, useSelector } from 'react-redux';
import { D_API, D_API_FHIR } from '../../../../services/APIService';
import UserService from '../../../../services/UserService';
import { ConstructionOutlined } from '@mui/icons-material';
import { URI } from '../../../utility/uri';


const Services = () => {
  const [addservice, setAddService] = useState(false)
  const [edit, setEdit] = useState(false)
  const [toBeEditId, setToBeEditId] = useState('')

  const [showAll, setShowAll] = useState(true)

  const [checkedItems, setCheckedItems] = useState([]); // object of checked checkboxes


  const [allServices, setAllServices] = useState([])
  const [selected, setSelected] = useState(null)

  const [filteredItems, setFilteredItems] = useState([])

  const [allspecialty, setAllSpeciality] = useState([])
  const [allspecialtyDisplay, setAllSpecialityDisplay] = useState([])

  const [withOutCheckGetData, setWithOutCheckGetData] = useState([])
  const [withCheckGetData, setWithCheckGetData] = useState([])

  const userOrg = useSelector(state => state.registration.userOrg);
  console.log(userOrg, "000000")

  const userProfile = useSelector((state) => state.userProfile.userProfile);
  console.log(userProfile, "userProfile")

  const location = useSelector((state) => state.registration.location)

  const isAdmin = useSelector((state) => state.registration.isAdmin);



  const dispatch = useDispatch()

  useEffect(() => {
    defaultSpecialtyVal()
  }, [])



  useEffect(() => {
    if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
        dispatch({
          type: "IS_ADMIN",
          payload: res.data
        })
      }).catch((err) => {
        dispatch({
          type: "IS_ADMIN",
          payload: null
        })
        console.log(err)
      })

    } else {
      dispatch({
        type: "IS_ADMIN",
        payload: null
      })
    }



  }, [userOrg?.id])



  useEffect(() => {
    if (showAll) {
      setFilteredItems(allspecialty)
    } else {
      setFilteredItems([])
    }
  }, [showAll])


  useEffect(() => {
    // let data = allspecialty?.length > 0 && allspecialtyTrue ? allspecialty : allhealthCare
    // let data = allspecialty
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : allspecialty    
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0  ? [] : allspecialty
    // let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0  ? [] : allspecialty
    let data = withCheckGetData.length > 0 ? withCheckGetData : withOutCheckGetData.length > 0 ? withOutCheckGetData : withOutCheckGetData.length == 0 && checkedItems.length !== 0 ? [] : []
    if (selected == null) {
      data = allspecialty
    }

    // if(selected == null && checkedItems.length == 0){
    //   setFilteredItems([])
    //   return
    // }

    console.log(data, allspecialty, 'ppppp')

    if (checkedItems.length == 0) {
      setFilteredItems(data)
    } else {
      const filteredItems = data?.filter((item) => {
        return checkedItems.includes(item?.resource?.specialty?.[0]?.coding?.[0]?.code);

      });
      setFilteredItems(filteredItems)
    }



  }, [checkedItems, withCheckGetData, withOutCheckGetData, allspecialty])

  console.log(filteredItems, "file")


  useEffect(() => {
    if (selected) {
      getServiceCode(selected.id)
      console.log("hey")
    } else if (showAll) {

    }
  }, [checkedItems])

  useEffect(() => {
    if (location?.id) {
      D_API.get(`${userOrg.id}/Location/partof/${location.id}`, {
        headers: { Authorization: `bearer ${UserService.getToken()}` },
      }).then(res => {
        // console.log(res.data.id, "llllllll1gfrftghjkl")
        // dispatch({ type: "Level_TWO_ID", payload: res.data.id })
        dispatch({ type: "Level_TWO_ID", payload: res.data.entry?.[0]?.resource?.id ? res.data.entry?.[0]?.resource?.id : "" })
        setAllServices(res.data.entry?.[0] ? [{ ...res.data.entry?.[0] }] : [])
      })
    }


  }, [location])

  console.log(allServices, "allServices")

  console.log(userOrg)

  useEffect(() => {
    D_API_FHIR.get(`PractitionerRole?organization=${userOrg?.id}`).then((res) => {
      console.log(res)
    }).catch((err) => console.log(err))
  }, [])

  const addServiceFn = () => {
    setAddService(!addservice)
    if (edit) {
      setEdit(false)
    }
    if (toBeEditId) {
      setToBeEditId("")
    }
  }

  const editServiceFn = (id) => {
    console.log(id, "hey")
    setToBeEditId(id)

    setEdit(true)
    setAddService(true)
  }

  const handleChange = (event) => {
    const value = event.target.value;
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value))
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  const getServiceCode = async (l2id) => {

    if (checkedItems.length == 0) {
      setFilteredItems([])
      await D_API.get(`https://devfhir.docsteth.com/fhir-server/api/v4/HealthcareService?coverage-area=${l2id}`)
        .then(res => {
          console.log(res.data.entry, "response new")
          let data = res.data?.entry ? res.data?.entry : []
          console.log(data, "getServiceCode function res one")
          setWithOutCheckGetData(data)
        })
        .catch(err => {
          console.log(err)
        })
    }
    else {

      let array = []
      setFilteredItems([])

      for (let i = 0; i < checkedItems.length; i++) {
        await D_API.get(`https://devfhir.docsteth.com/fhir-server/api/v4/HealthcareService?specialty=${checkedItems[i]}&coverage-area=${l2id}`)
          .then(res => {
            console.log(res.data.entry, "response new")
            let data = res.data?.entry ? res.data?.entry : []
            console.log(data, "getServiceCode function res")
            if (data[0]) {
              array.push(data[0])
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      console.log(array, "push")
      setWithCheckGetData(array)
    }
  }



  const handleSelect = (item) => {
    console.log(item, '890-', selected === item)
    setFilteredItems([])
    setSelected(null)
    setShowAll(false)

    getServiceCode(item.id)
    setSelected(item)

    // if (!selected) {
    //   setSelected(item)
    //   getServiceCode(item.id)
    // } else {
    //   setSelected(null)
    // }
  }

  const handelAll = (() => {
    setShowAll(true)
    setSelected(null)
  })

  const reset = () => {
    setCheckedItems([])
    setFilteredItems([])

    setSelected(null)
  }

  const defaultSpecialtyVal = async () => {
    let resdata;

    await D_API.get(`${userOrg?.id}/HealthcareService/organization/${userOrg?.id}`, {
      headers: { Authorization: `bearer ${UserService.getToken()}` },
    }).then(res => {
      resdata = res?.data?.entry
      setAllSpeciality(res?.data?.entry)
      console.log(resdata, "hhhhhhl")
    }).catch(err => {
      console.log(err)
    })


    if (resdata?.length > 0) {
      let uniquearr = []
      let compare = []

      resdata.map((item, i) => {

        if (!compare.includes(item?.resource?.specialty?.[0]?.coding?.[0]?.code)) {
          compare.push(item?.resource?.specialty?.[0]?.coding?.[0]?.code) //passing unique val if not exist
          uniquearr.push(item)
        }
      })

      // setAllSpeciality(uniquearr)
      setAllSpecialityDisplay(uniquearr)
    }


  }



  return (
    <Card sx={{ p: { xs: "10px", sm: " 10px " } }}>

      <Grid container spacing={5}>
        {addservice && (
          <AddServices
            close={addServiceFn}
            allServices={allServices}
            selectedItem={selected}
            edit={edit}
            toBeEditId={toBeEditId}
            reset={reset}
          />
        )}
        <Grid item xs={12} sm={12} md={3}>
          <Card sx={{ padding: "1rem", boxShadow: 3 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#750083",
                display: "flex",
                alignItems: "center",
                margin: "12px 0",
              }}
            >
              <img src={StethscopeImg} alt="error" />
              &nbsp;&nbsp;Specialities
            </Typography>

            <FormGroup>
              {allspecialtyDisplay?.map((item) => {
                return (
                  <Tooltip
                    title={item?.resource?.specialty?.[0]?.coding?.[0]?.display}
                  >
                    <FormControlLabel
                      className="text-ellipsis"
                      control={
                        <Checkbox
                          checked={checkedItems.item}
                          onChange={handleChange}
                          value={
                            item?.resource?.specialty?.[0]?.coding?.[0]?.code
                          }
                        />
                      }
                      label={
                        item?.resource?.specialty?.[0]?.coding?.[0]?.display
                      }
                    />
                  </Tooltip>
                );
              })}
            </FormGroup>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={9}>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", margin: "12px 0" }}
          >
            &nbsp;Service Areas
          </Typography>
          <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <Button
              onClick={() => handelAll()}
              variant={showAll ? "contained" : "outlined"}
              sx={{
                boxShadow: 3,
                borderRadius: "20px",
                textTransform: "capitalize",
                color: showAll ? "white" : "black",
                border: ".5px solid #EBEBEB",
                padding: "4px 10px !important",
              }}
            >
              all
            </Button>

            {allServices?.map((item) => {
              return (
                <Button
                  onClick={() => handleSelect(item?.resource)}
                  variant={
                    item?.resource?.id == selected?.id
                      ? "contained"
                      : "outlined"
                  }
                  sx={{
                    boxShadow: 3,
                    borderRadius: "20px",
                    textTransform: "capitalize",
                    color:
                      item?.resource?.id == selected?.id ? "white" : "black",
                    border: ".5px solid #EBEBEB",
                    padding: "4px 10px !important",
                  }}
                >
                  {item?.resource?.name}
                </Button>
              );
            })}
          </div>
          <Typography
            variant="h6"
            sx={{
              color: "#750083",
              display: "flex",
              alignItems: "center",
              margin: "12px 0",
            }}
          >
            &nbsp;&nbsp;Medical Service
          </Typography>
          <Grid container item spacing={2}>
            {filteredItems?.map((item) => {
              return (
                <Grid xs={12} sm={12} item md={6}>
                  <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "500" }}
                            mb={1}
                          >
                            {item?.resource?.name}
                          </Typography>
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <TaskAltIcon fontSize="small" />
                            &nbsp;Active
                          </Typography>
                        </div>
                        <img
                          src={Edit}
                          onClick={() => editServiceFn(item?.resource?.id)}
                          height="18"
                          alt="edit"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Typography sx={{ fontWeight: "500" }} variant="body1">
                        {item?.resource?.category?.[0]?.coding?.[0]?.display}
                      </Typography>
                      <Typography sx={{ color: "#5C5C5C" }}>
                        {item?.resource?.specialty?.[0]?.coding?.[0]?.display}
                      </Typography>

                      <Typography
                        sx={{ fontWeight: "500" }}
                        variant="body1"
                        my={1}
                      >
                        {item?.resource?.name}
                      </Typography>
                      <Typography sx={{ color: "#5C5C5C" }}>
                        Eligibility
                      </Typography>
                    </CardContent>
                    <div style={{ display: "flex" }}>
                      <Button
                        sx={{
                          flex: 1,
                          borderRadius: "0",
                          textTransform: "capitalize",
                          backgroundColor: "#EBF4F9",
                          color: "#348FC6",
                        }}
                        variant="contained"
                      >
                        Learn more
                      </Button>
                      <Button
                        sx={{
                          flex: 1,
                          borderRadius: "0",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        Request Appointment
                      </Button>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {
            isAdmin === "PERMIT" &&

            <Typography
              onClick={() => addServiceFn()}
              variant="h6"
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#0B6F07",
                fontWeight: "500",
                cursor: 'pointer'
              }}
            >
              <AddCircleRoundedIcon />
              &nbsp;Healthcare Service
            </Typography>
          }


          {/* <Typography
            variant="h6"
            sx={{
              color: "#750083",
              display: "flex",
              alignItems: "center",
              margin: "12px 0",
            }}
          >
            &nbsp;&nbsp;Practitioners
          </Typography> */}

          {/* <Card
            sx={{
              maxWidth: "300px",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <Box sx={{ boxShadow: 4, width: "50px", height: "50px" }}>
                <img
                  style={{ borderRadius: "4px", border: "2px solid white" }}
                  width="48"
                  height="48"
                  src="https://images.unsplash.com/photo-1680462155279-4d73bdbf46ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
                />
              </Box>
              <div>
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  Dr Name
                </Typography>
                <Typography variant="body2">mbbs</Typography>
                <Typography variant="body2">role</Typography>
              </div>
            </div>
            <div
              style={{
                background: "#7D288B",
                display: "flex",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "white" }} />
            </div>
          </Card> */}
        </Grid>
      </Grid>
    </Card>
  );
}

export default Services