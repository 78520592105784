import React, { useState, useEffect } from 'react';

import location_on from '../../Atom/modifiedicons/location_on.svg'
import emailIcon from "../../../images/mail.svg"
import call from "../../Atom/modifiedicons/call.svg"
import { Button, Dialog, Box, Divider, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Verified from "../../../icons/verifieduser.svg";
import ErrorIcon from '@mui/icons-material/Error';
import UserService from '../../../services/UserService';
import { DOCS_WITHOUT_TOKEN } from '../../../services/APIService';
import { useSelector, useDispatch } from 'react-redux';
import DialogBox from '../../DialogBox/dialog';
import { updateProfile } from '../../../redux/Actions/actions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CustomInput from '../../common/inputs/customInput';
import './profile.css';
import "../../utility/style.css";
import '../../peers/peer.css';

const ProfileInfo = ({ phoneDetails, emailDetails, addressDetails, profileQualif, setFnView }) => {

    const [dialogphone, setDialogphone] = useState(false);
    const [dialogopen, setDialogopen] = useState(false);
    const [view, setView] = useState(false);
    const [usersDatas, setUsersDatas] = useState([]);
    const [user, setUser] = useState({});

    const state = useSelector(state => state.userProfile.userProfile);
    const dispatch = useDispatch()
    console.log(phoneDetails, "phoneDetails", emailDetails, addressDetails, profileQualif, state)

    useEffect(() => {
        DOCS_WITHOUT_TOKEN.get(`Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`)
            .then(res => {
                setUsersDatas(res?.data?.entry?.[0] || {})
            })
            .catch(err => console.log(err))
    }, [])


    const showmore = () => {
        setDialogopen(true)
    }
    const handledialogclose = () => {
        setDialogopen(false)
    }

    const handlephone = () => {
        setDialogphone(true)
    }

    const handlenumberclose = () => {
        setDialogphone(false)
    }




    return (
        <div className="p1">
            <div>
                <div className='t-center fntLg fntSemiBold pv1 capitalize'>
                    Medical Doctor
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="fntMd fntSemiBold" style={{ width: "60%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >List Of Degrees Pursed</div>
                    <div className="fntMd fntSemiBold" style={{ width: "40%", paddingRight: "10px", display: "flex", justifyContent: "flex-end" }}  >Verify HPR</div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }} >
                    {
                        profileQualif?.length > 0 ?

                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '60%' }}>
                                {
                                    profileQualif.map((e, index) => (
                                        e?.degree?.display && e?.course?.display ? (
                                            <div key={index} style={{ display: "flex" }}>
                                                <div >
                                                    {e?.degree?.display}
                                                </div>
                                                {/* <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>
                                        {`- ${e.course.display}`}
                                    </div> */}
                                            </div>
                                        ) : "N/A"
                                    ))
                                }
                            </div>
                            : < div className=''>N/A</div>
                    }
                    <div style={{ width: "40%", paddingLeft: "0px", display: "flex", justifyContent: "flex-end" }}>
                        {
                            usersDatas?.resource?.active === true ?
                                <div>
                                    <Button className='capitalize' variant="outlined" color="success" sx={{ padding: "5px 5px !important", height: "30px", width: "100px" }}>Verified<img src={Verified} style={{ paddingLeft: "5px" }} width="" height=""></img></Button>
                                </div>
                                :
                                usersDatas?.resource?.active === false && (usersDatas?.resource?.identifier === null || state?.practioner?.identifier?.length >= 3) ?
                                    <div >
                                        <Button variant="outlined" color="error" className='capitalize' sx={{ padding: "5px 5px !important", height: "30px", width: "100px" }}>Pending</Button>
                                    </div>
                                    :
                                    <div>
                                        <Button variant="outlined" color="error" className='capitalize' sx={{ padding: "5px 5px !important", height: "30px", width: "100px" }} onClick={() => setFnView(true)} >Get Verified</Button>
                                    </div>
                        }



                        {/* <Button className='verify_text'><img src={Verified} style={{ paddingRight: "10px" }} width="20px" height="20px"></img>Verified</Button> */}
                    </div>
                </div>
            </div>


            <div className='pl2 pv1'>
                <div className='capitalize fntMd fntSemiBold'> Contact Details</div>
                <div className="pt1">
                    <img src={call} alt="call icon" style={{ fontSize: "16px" }} />
                    <span className='pl1'>{phoneDetails?.[0]?.value || "N/A"}</span>
                    {phoneDetails?.length > 1 ?
                        <span onClick={handlephone} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                            {"+" + (phoneDetails?.length - 1) + "more"}
                        </span> : ""
                    }
                </div>
                {/* below code to show extra details of phone number */}
                <Dialog
                    open={dialogphone}
                    onClose={handlenumberclose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box className='contact_details'>
                        <div className='space_btw_center'>
                            <h5>Contact Details</h5>
                            <ClearIcon className='cursor' onClick={handlenumberclose} />
                        </div>
                        <div className="pt-2 sidebar__pt ">
                            <ul className="ta__center" id="mobilecontact_list">
                                {phoneDetails?.map((element, index) => {
                                    return (
                                        <div className="mt-10" key={index}>
                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {element.value}
                                            </li>
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>
                    </Box>
                </Dialog>
                <div className="pt1">
                    <img src={emailIcon} alt="email icon" style={{ fontSize: "16px" }} />
                    <span className='pl1'>{emailDetails?.[0]?.value || "N/A"} </span>
                    {emailDetails?.length > 1 ?
                        <span onClick={showmore} className="cursor" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                            {"+" + (emailDetails?.length - 1) + "more"}
                        </span> : ""
                    }
                </div>
                <Dialog
                    open={dialogopen}
                    onClose={handledialogclose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box className='contact_details'>
                        <div className='space_btw_center'>
                            <h5>Contact Details</h5>
                            <ClearIcon className='cursor' onClick={handledialogclose} />
                        </div>
                        <div className="pt-2 sidebar__pt ">
                            <ul className="ta__center" id="mobilecontact_list">
                                {emailDetails?.map((element, index) => {
                                    return (
                                        <div className="mt-10" key={index}>
                                            <li style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                                {element.value}
                                            </li>
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>
                    </Box>
                </Dialog>
                <div className="pt1">
                    <img src={location_on} alt="location icon" style={{ fontSize: "16px" }} />
                    <span className="pl1" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>
                        {addressDetails?.city || addressDetails?.state || addressDetails?.country ? (
                            <>
                                <span>{addressDetails?.city || ''}</span>{" "}
                                {addressDetails?.city && addressDetails?.state ? ',' : ''}
                                <span>{addressDetails?.state ? addressDetails?.state + ',' : ''}</span>{" "}
                                <span>{addressDetails?.country || ''}</span>
                            </>
                        ) : 'N/A'}
                    </span>
                </div>
            </div>


            {/* <DialogBox open={view} handleDialog={() => setView(false)}
                Title={`Get Verified With Ayushman Bharath Digital Health Mission`} onClose={() => setView(false)}
                Content={<>
                    <Divider />
                    <ValidatorForm onSubmit={() => submitHpr()}>
                        <h3 className='mt1'>Existing HPR - User</h3>
                        <div className='flx-center'>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <CustomInput type='Number' placeholder="Enter 14 Digits HPR ID" Icon={<>@hpr.abdm</>} value={hprId} name="userName"
                                        handleChange={(name, value) => setHprId(value?.slice(0, 14))}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type='submit' sx={{ color: 'red' }} className={!hprId ? "" : 'clr-btn'} variant={"contained"} disabled={hprId.length !== 14}
                                    >{"Submit"}</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </ValidatorForm>
                </>

                }
            /> */}
        </div >

    )
}

export default ProfileInfo;
